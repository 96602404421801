define("discourse/plugins/chat/discourse/components/chat-channel-leave-btn", ["exports", "@glimmer/component", "@ember/service", "@ember/utils", "discourse/components/d-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _utils, _dButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatChannelLeaveBtn extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get shouldRender() {
      return this.site.desktopView && (0, _utils.isPresent)(this.args.channel);
    }
    get leaveChatTitleKey() {
      if (this.args.channel.isDirectMessageChannel) {
        return "chat.direct_messages.leave";
      } else {
        return "chat.leave";
      }
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldRender}}
          <DButton
            @icon="times"
            @action={{@onLeaveChannel}}
            @title={{this.leaveChatTitleKey}}
            class="btn-flat chat-channel-leave-btn"
          />
        {{/if}}
      
    */
    {
      "id": "KCZBWLrR",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"btn-flat chat-channel-leave-btn\"]],[[\"@icon\",\"@action\",\"@title\"],[\"times\",[30,1],[30,0,[\"leaveChatTitleKey\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@onLeaveChannel\"],false,[\"if\"]]",
      "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-channel-leave-btn.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatChannelLeaveBtn;
});