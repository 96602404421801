define("discourse/plugins/chat/discourse/components/chat-skeleton", ["exports", "@glimmer/component", "@ember/template", "@ember/component", "@ember/template-factory"], function (_exports, _component, _template2, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatSkeleton extends _component.default {
    get placeholders() {
      return Array.from({
        length: 15
      }, () => {
        return {
          image: this.#randomIntFromInterval(1, 10) === 5,
          rows: Array.from({
            length: this.#randomIntFromInterval(1, 5)
          }, () => {
            return (0, _template2.htmlSafe)(`width: ${this.#randomIntFromInterval(20, 95)}%`);
          }),
          reactions: Array.from({
            length: this.#randomIntFromInterval(0, 3)
          })
        };
      });
    }
    #randomIntFromInterval(min1, max1) {
      return Math.floor(Math.random() * (max1 - min1 + 1) + min1);
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="chat-skeleton -animation">
          {{#each this.placeholders as |placeholder|}}
            <div class="chat-skeleton__body">
              <div class="chat-skeleton__message">
                <div class="chat-skeleton__message-avatar"></div>
                <div class="chat-skeleton__message-poster"></div>
                <div class="chat-skeleton__message-content">
                  {{#if placeholder.image}}
                    <div class="chat-skeleton__message-img"></div>
                  {{/if}}
    
                  <div class="chat-skeleton__message-text">
                    {{#each placeholder.rows as |row|}}
                      <div class="chat-skeleton__message-msg" style={{row}}></div>
                    {{/each}}
                  </div>
    
                  {{#if placeholder.reactions}}
                    <div class="chat-skeleton__message-reactions">
                      {{#each placeholder.reactions}}
                        <div class="chat-skeleton__message-reaction"></div>
                      {{/each}}
                    </div>
                  {{/if}}
                </div>
              </div>
            </div>
          {{/each}}
        </div>
      
    */
    {
      "id": "QvQVFvq5",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-skeleton -animation\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"placeholders\"]]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"chat-skeleton__body\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"chat-skeleton__message\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"chat-skeleton__message-avatar\"],[12],[13],[1,\"\\n            \"],[10,0],[14,0,\"chat-skeleton__message-poster\"],[12],[13],[1,\"\\n            \"],[10,0],[14,0,\"chat-skeleton__message-content\"],[12],[1,\"\\n\"],[41,[30,1,[\"image\"]],[[[1,\"                \"],[10,0],[14,0,\"chat-skeleton__message-img\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n              \"],[10,0],[14,0,\"chat-skeleton__message-text\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1,[\"rows\"]]],null]],null],null,[[[1,\"                  \"],[10,0],[14,0,\"chat-skeleton__message-msg\"],[15,5,[30,2]],[12],[13],[1,\"\\n\"]],[2]],null],[1,\"              \"],[13],[1,\"\\n\\n\"],[41,[30,1,[\"reactions\"]],[[[1,\"                \"],[10,0],[14,0,\"chat-skeleton__message-reactions\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1,[\"reactions\"]]],null]],null],null,[[[1,\"                    \"],[10,0],[14,0,\"chat-skeleton__message-reaction\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"                \"],[13],[1,\"\\n\"]],[]],null],[1,\"            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"placeholder\",\"row\"],false,[\"each\",\"-track-array\",\"if\"]]",
      "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-skeleton.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatSkeleton;
});