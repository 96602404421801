define("discourse/plugins/chat/discourse/components/chat-channel-message-emoji-picker", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@popperjs/core", "ember-modifier", "discourse/lib/offset-calculator", "discourse/plugins/chat/discourse/components/chat-emoji-picker", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _core, _emberModifier, _offsetCalculator, _chatEmojiPicker, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatChannelMessageEmojiPicker extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatEmojiPickerManager", [_service.service]))();
    #chatEmojiPickerManager = (() => (dt7948.i(this, "chatEmojiPickerManager"), void 0))();
    context = "chat-channel-message";
    listenToBodyScroll = (() => (0, _emberModifier.modifier)(() => {
      const handler1 = () => {
        this.chatEmojiPickerManager.close();
      };
      document.addEventListener("scroll", handler1);
      return () => {
        document.removeEventListener("scroll", handler1);
      };
    }))();
    didSelectEmoji(emoji1) {
      this.chatEmojiPickerManager.picker?.didSelectEmoji(emoji1);
      this.chatEmojiPickerManager.close();
    }
    static #_3 = (() => dt7948.n(this.prototype, "didSelectEmoji", [_object.action]))();
    didInsert(element1) {
      if (this.site.mobileView) {
        element1.classList.remove("hidden");
        return;
      }
      this._popper = (0, _core.createPopper)(this.chatEmojiPickerManager.picker?.trigger, element1, {
        placement: "top",
        modifiers: [{
          name: "eventListeners",
          options: {
            scroll: false,
            resize: false
          }
        }, {
          name: "flip",
          options: {
            padding: {
              top: (0, _offsetCalculator.headerOffset)()
            }
          }
        }]
      });
      element1.classList.remove("hidden");
    }
    static #_4 = (() => dt7948.n(this.prototype, "didInsert", [_object.action]))();
    willDestroy() {
      super.willDestroy(...arguments);
      this._popper?.destroy();
    }
    static #_5 = (() => dt7948.n(this.prototype, "willDestroy", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <ChatEmojiPicker
          {{this.listenToBodyScroll}}
          @context="chat-channel-message"
          @didInsert={{this.didInsert}}
          @willDestroy={{this.willDestroy}}
          @didSelectEmoji={{this.didSelectEmoji}}
          class="hidden"
        />
      
    */
    {
      "id": "PczBwD4H",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"hidden\"],[4,[30,0,[\"listenToBodyScroll\"]],null,null]],[[\"@context\",\"@didInsert\",\"@willDestroy\",\"@didSelectEmoji\"],[\"chat-channel-message\",[30,0,[\"didInsert\"]],[30,0,[\"willDestroy\"]],[30,0,[\"didSelectEmoji\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-channel-message-emoji-picker.js",
      "scope": () => [_chatEmojiPicker.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatChannelMessageEmojiPicker;
});