define("discourse/plugins/chat/discourse/components/chat-upload", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "@ember/service", "@ember/template", "truth-helpers", "discourse/lib/uploads", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _modifier, _object, _service, _template2, _truthHelpers, _uploads, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatUpload extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "loaded", [_tracking.tracked], function () {
      return false;
    }))();
    #loaded = (() => (dt7948.i(this, "loaded"), void 0))();
    IMAGE_TYPE = "image";
    VIDEO_TYPE = "video";
    AUDIO_TYPE = "audio";
    ATTACHMENT_TYPE = "attachment";
    get type() {
      if ((0, _uploads.isImage)(this.args.upload.original_filename)) {
        return this.IMAGE_TYPE;
      }
      if ((0, _uploads.isVideo)(this.args.upload.original_filename)) {
        return this.VIDEO_TYPE;
      }
      if ((0, _uploads.isAudio)(this.args.upload.original_filename)) {
        return this.AUDIO_TYPE;
      }
      return this.ATTACHMENT_TYPE;
    }
    get size() {
      const width1 = this.args.upload.width;
      const height1 = this.args.upload.height;
      const ratio1 = Math.min(this.siteSettings.max_image_width / width1, this.siteSettings.max_image_height / height1);
      return {
        width: width1 * ratio1,
        height: height1 * ratio1
      };
    }
    get imageUrl() {
      return this.args.upload.thumbnail?.url ?? this.args.upload.url;
    }
    get imageStyle() {
      if (this.args.upload.dominant_color && !this.loaded) {
        return (0, _template2.htmlSafe)(`background-color: #${this.args.upload.dominant_color};`);
      }
    }
    imageLoaded() {
      this.loaded = true;
    }
    static #_3 = (() => dt7948.n(this.prototype, "imageLoaded", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if (eq this.type this.IMAGE_TYPE)}}
          <img
            class="chat-img-upload"
            data-orig-src={{@upload.short_url}}
            data-large-src={{@upload.url}}
            height={{this.size.height}}
            width={{this.size.width}}
            src={{this.imageUrl}}
            style={{this.imageStyle}}
            loading="lazy"
            tabindex="0"
            data-dominant-color={{@upload.dominant_color}}
            {{on "load" this.imageLoaded}}
          />
        {{else if (eq this.type this.VIDEO_TYPE)}}
          <video class="chat-video-upload" preload="metadata" height="150" controls>
            <source src={{@upload.url}} />
          </video>
        {{else if (eq this.type this.AUDIO_TYPE)}}
          <audio class="chat-audio-upload" preload="metadata" controls>
            <source src={{@upload.url}} />
          </audio>
        {{else}}
          <a
            class="chat-other-upload"
            data-orig-href={{@upload.short_url}}
            href={{@upload.url}}
          >
            {{@upload.original_filename}}
          </a>
        {{/if}}
      
    */
    {
      "id": "rDD0/Jvf",
      "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"type\"]],[30,0,[\"IMAGE_TYPE\"]]],null],[[[1,\"      \"],[11,\"img\"],[24,0,\"chat-img-upload\"],[16,\"data-orig-src\",[30,1,[\"short_url\"]]],[16,\"data-large-src\",[30,1,[\"url\"]]],[16,\"height\",[30,0,[\"size\",\"height\"]]],[16,\"width\",[30,0,[\"size\",\"width\"]]],[16,\"src\",[30,0,[\"imageUrl\"]]],[16,5,[30,0,[\"imageStyle\"]]],[24,\"loading\",\"lazy\"],[24,\"tabindex\",\"0\"],[16,\"data-dominant-color\",[30,1,[\"dominant_color\"]]],[4,[32,1],[\"load\",[30,0,[\"imageLoaded\"]]],null],[12],[13],[1,\"\\n\"]],[]],[[[41,[28,[32,0],[[30,0,[\"type\"]],[30,0,[\"VIDEO_TYPE\"]]],null],[[[1,\"      \"],[10,\"video\"],[14,0,\"chat-video-upload\"],[14,\"preload\",\"metadata\"],[14,\"height\",\"150\"],[14,\"controls\",\"\"],[12],[1,\"\\n        \"],[10,\"source\"],[15,\"src\",[30,1,[\"url\"]]],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[32,0],[[30,0,[\"type\"]],[30,0,[\"AUDIO_TYPE\"]]],null],[[[1,\"      \"],[10,\"audio\"],[14,0,\"chat-audio-upload\"],[14,\"preload\",\"metadata\"],[14,\"controls\",\"\"],[12],[1,\"\\n        \"],[10,\"source\"],[15,\"src\",[30,1,[\"url\"]]],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,3],[14,0,\"chat-other-upload\"],[15,\"data-orig-href\",[30,1,[\"short_url\"]]],[15,6,[30,1,[\"url\"]]],[12],[1,\"\\n        \"],[1,[30,1,[\"original_filename\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]],[]]]],[]]],[1,\"  \"]],[\"@upload\"],false,[\"if\"]]",
      "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-upload.js",
      "scope": () => [_truthHelpers.eq, _modifier.on],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatUpload;
});